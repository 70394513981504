import PersonIcon from "@mui/icons-material/Person"
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from '@mui/material'
import moment from "moment/moment"
import { useRedirect } from "react-admin"


export const ChatItemList = ({chat, userId}) => {
  const redirect = useRedirect()

  const currentDate = moment(chat?.lastMessage?.updatedAt)
  const diff = moment.duration(moment().diff(currentDate))

  return (
    <ListItem
      onClick={() => redirect(`${chat.id}`, 'chats')}
      sx={{
        ...(chat.unreadMessagesCount !== 0
          ? { backgroundColor: "rgba(13,125,237,0.5)" }
          : { position: "relative" }
        ),
        "&:hover": {
          backgroundColor: "#f0f0f0",
        },
        cursor: "pointer",
      }}
    >
      <ListItemAvatar
        sx={{
          minWidth: 40,
          width: 40,
          height: 40,
          borderRadius: "50%",
          overflow: "hidden",
          marginRight: 2,
          backgroundColor: "#c6bfbf",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {
          Array.isArray(chat?.user?.properties?.avatar?.value)
                  && chat?.user?.properties?.avatar?.value.length > 0
                  && chat?.user?.properties?.avatar?.value[0]?.url
            ? <img
              src={`${chat?.user?.properties?.avatar?.value[0].url}`}
              height="100%"
              width="100%"
              alt="avatar"
              style={{
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
            : <PersonIcon />
        }
      </ListItemAvatar>
      <ListItemText
        primary={
          !(chat?.user?.properties?.name?.value && chat?.user?.properties?.surname?.value)
            ? `${chat?.id}_user_${chat?.user?.id}(${chat?.user?.roles[0].name ?? ''})`
            : `${chat?.id}_${chat?.user?.properties?.name?.value ?? ''} ${chat?.user?.properties?.surname?.value ?? ''}` + `(${chat?.user?.roles[0].name ?? ''})`
        }
        secondary={
          chat?.lastMessage?.media.length
            ? chat?.lastMessage?.media.map(image => (
              <span key={`${image.id}`} style={{ display: "flex"}}>
                <img src={`${image.url}`} alt="img" width={15} height={15} />
                <div style={{ marginLeft: 5, display: "block", textOverflow: "ellipsis", overflow: "hidden", width: "300px", whiteSpace: 'nowrap' }} >
                  {
                    chat?.lastMessage?.text
                      ? chat?.lastMessage?.text
                      : 'Photo'
                  }
                </div>
              </span>
            ))
            : chat?.lastMessage?.text
              ?
              <div style={{ display: "block", textOverflow: "ellipsis", overflow: "hidden", width: "300px", whiteSpace: 'nowrap' }}>
                {chat?.lastMessage?.text}
              </div>
              : ''
        }
      />
      <Typography
        sx={{ marginBottom: 2, fontSize: 16 }}
      >
        {
          diff.asHours() < 18
            ? `${currentDate.format('HH:mm')}`
            : diff.asDays() < 6
              ? `${currentDate.format('dddd')}`
              : `${currentDate.format('DD-MM-YYYY')}`
        }
        {
          chat.unreadMessagesCount !== 0
          && <span style={{
            width: '20px',
            height: '20px',
            backgroundColor: '#ac9f9f',
            color: '$f00',
            borderRadius: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            bottom: "10px"
          }}>
            {chat.unreadMessagesCount}
          </span>
        }
      </Typography>
    </ListItem>
  )
}