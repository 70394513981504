import {
  ArrayField,
  Labeled,
  TextField,
  useResourceContext,
  ReferenceManyField,
  Datagrid,
} from 'react-admin'
import { resourceList } from '~/modules/resource-list'
import React, { useEffect, useState } from 'react'
import { parseResource } from '~/modules/parse-resource'
import { EntityName } from '~/constants'
import { getFieldForApp } from '~/utils/getField'

export const TableForRelations = () => {
  const resource = useResourceContext()
  const [applicationId, resourceName] = resource.split(':')
  const [template, setTemplate] = useState<EntityTemplate>()
  const [entityName, setEntityName] = useState<EntityName>()

  useEffect(() => {
    const loadTemplate = async () => {

      const { entityName: name, template } = await parseResource(resource)

      setTemplate(template)
      setEntityName(name)
    }

    loadTemplate()
  }, [resource])

  if (!template || !entityName) {
    return null
  }

  const setRelations = new Set()

  if (template.relations.length === 0) {
    return null
  }

  for (let relation of template.relations) {
    setRelations.add(relation.typeId === 2)
  }

  if (setRelations.size === 1 && setRelations.has(false)) return null

  const childResource = template.relations.find(el => el.typeId === 2)?.template
    ?.symbolCode

  if (childResource === 'review') return null

  const label = resourceList.find(el => el.name === '1:' + childResource)
    ?.options?.label

  return (
    <Labeled label={label}>
      <ChildComponent resource={childResource} applicationId={applicationId}/>
    </Labeled>
  )
}

const ChildComponent = ({ resource,applicationId }) => {
  const [template, setTemplate] = useState<EntityTemplate>()
  const [entityName, setEntityName] = useState<EntityName>()

  useEffect(() => {
    const loadTemplate = async () => {
      const { entityName: name, template } = await parseResource(
        `${applicationId}:` + resource
      )

      setTemplate(template)
      setEntityName(name)
    }

    loadTemplate()
  }, [resource])

  if (!template || !entityName) {
    return null
  }

  return (
    <ArrayField source={`relations.${resource}`}>
      <ReferenceManyField
        reference={`${applicationId}:${resource}`}
        source={`relations.${resource}`}
        target={'id'}
      >
        <Datagrid rowClick={"show"} bulkActionButtons={false}>
          <TextField label={'ID'} source={'id'} />
          {resource.endsWith('Day') ? (
            <TextField label={'День'} source="properties.position.value" />
          ) : (
            <TextField label={'Название'} source="properties.name.value" />
          )}
          {template.properties.map(
            el =>
              el.symbolCode !== 'name' &&
              el.symbolCode !== 'position' &&
              getFieldForApp(el)
          )}
        </Datagrid>
      </ReferenceManyField>
    </ArrayField>
  )
}
