import { useRecordContext } from 'react-admin'
import { Stack } from '@mui/material'
import { Player } from '@lottiefiles/react-lottie-player'
import React from 'react'

export const AnimationField = ({ el, props }) => {
  const record = useRecordContext(props)
  if (!record?.properties?.animation?.value && !el) return null

  return (
    <>
      <Stack flexDirection={'row'}>
        {(el ? [el] : record.properties.animation.value).map(a => {
          return (
            <Stack key={`${a.id}-${a.name}-${a.symbolCode}`} padding={'16px'}>
              {a?.mime === 'application/json' && (
                <Player
                  autoplay
                  loop
                  src={a?.url || a?.src}
                  style={{ height: '240px', width: '320px' }}
                />
              )}
              {a?.mime === 'video/mp4' && (
                <video
                  src={a?.url || a?.src}
                  controls
                  width="320"
                  height="240"
                ></video>
              )}
            </Stack>
          )
        })}
      </Stack>
    </>
  )
}
