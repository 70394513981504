import Api from '~/api/wrapper-api'
import { DataProvider } from 'react-admin'
import {
  parserParamsForGetList,
  parserParamsForGetManyReference,
} from '~/utils/dataProviderParams'
import {
  Response,
  ResponseArrayNumbers,
  ResponseArrayObjects,
  ResponseObject,
} from '~/utils/types'

export const entitiesTemplatesDataProvider: DataProvider = {
  async getList(resource: string, params): Promise<Response> {
    const filter = parserParamsForGetList(params, resource)
    const res = await Api.getList(resource, { ...filter })
    return { data: res.data.rows, total: res.data.count }
  },
  async getMany(
    resource: string,
    { ids }: { ids: ResponseArrayNumbers }
  ): Promise<ResponseArrayObjects> {
    const res = await Api.getList(resource, { filter: { id: ids } })
    return { data: res.data.rows }
  },
  async getManyReference(resource: string, params): Promise<Response> {
    const filter = parserParamsForGetManyReference(params, resource)
    const res = await Api.getList(resource, { ...filter })
    return { data: res.data.rows, total: res.data.count }
  },
  async getOne(
    resource: string,
    { id }: { id: number }
  ): Promise<ResponseObject> {
    const res = await Api.get(resource, id)
    const propertiesId = res.data.properties.map(property => property.id)
    const rolesInfo = res.data.roles.map(role => {
      return { roleId: role.roleId, typeId: role.typeId }
    })
    const relationsInfo = res.data.relations.map(relation => {
      return {
        typeId: relation.typeId,
        relatedTemplateId: relation.relatedTemplateId,
        onDeleteActionIds: relation.onDeleteActionId,
      }
    })
    return { data: { ...res.data, propertiesId, relationsInfo, rolesInfo } }
  },
  async create(
    resource: string,
    {
      data: {
        name,
        description,
        parentId,
        applicationId,
        symbolCode,
        rolesInfo,
        propertiesId,
        relationsInfo,
      },
    }
  ): Promise<ResponseObject> {
    const roles = rolesInfo.map(role => ({ ...role }))
    const properties = propertiesId.map(property => ({ propertyId: property }))
    const relations = relationsInfo.map(relationInfo => ({
      onDeleteActionId: relationInfo.onDeleteActionIds,
      relatedTemplateId: relationInfo.relatedTemplateId,
      typeId: relationInfo.typeId,
    }))
    const res = await Api.create(resource, {
      name,
      description,
      parentId,
      applicationId,
      symbolCode,
      roles,
      relations,
      properties,
    })
    return { data: res.data }
  },
  async update(
    resource: string,
    {
      data: {
        id,
        name,
        description,
        parentId,
        applicationId,
        symbolCode,
        rolesInfo,
        propertiesId,
        relationsInfo,
      },
    }
  ): Promise<ResponseObject> {
    console.log({
      id,
      name,
      description,
      parentId,
      applicationId,
      symbolCode,
      rolesInfo,
      propertiesId,
      relationsInfo,
    })
    const roles = rolesInfo.map(role => ({ ...role }))
    const properties = propertiesId.map(property => ({ propertyId: property }))
    const relations = relationsInfo.map(relationInfo => ({
      onDeleteActionId: relationInfo.onDeleteActionIds,
      relatedTemplateId: relationInfo.relatedTemplateId,
      typeId: relationInfo.typeId,
    }))
    const res = await Api.update(resource, id, {
      name,
      description,
      parentId,
      applicationId,
      symbolCode,
      roles,
      relations,
      properties,
    })
    return { data: res.data }
  },
  async updateMany(resource: string, params): Promise<ResponseObject> {
    console.log('🚧 UpdateMany:', resource, '\n', params)
    return { data: undefined }
  },
  async delete(
    resource: string,
    { id }: { id: number }
  ): Promise<ResponseObject> {
    const res = await Api.delete(resource, id)
    return { data: res.data }
  },
  async deleteMany(resource: string, params): Promise<ResponseObject> {
    console.log('🚧 deleteMany:', resource, '\n', params)
    return { data: undefined }
  },
}
