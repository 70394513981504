import _ from 'lodash'
import { assert } from '../utils/assertion'
import { EntityName } from '~/constants'

const compareNumeric = ({ properties: {position: { value: a}} }, { properties: {position: { value: b}} }) => {
  if (Number(a) > Number(b)) return 1
  if (Number(a) === Number(b)) return 0
  if (Number(a) < Number(b)) return -1
}

export const reverseTransformEntity = (obj: RawEntity) => {
  const result = {
    ...obj,
    properties: [],
  }

  if (_.isPlainObject(obj.properties)) {
    //@ts-ignore
    result.properties = transformDictToList(obj.properties)
  }

  if (_.isPlainObject(obj.relations)) {
    result.relations = []
    for (const [symbolCode, relation] of Object.entries(obj.relations)) {
      console.log([symbolCode, relation])
      result.relations.push({
        // ...relation,
        //@ts-ignore
        entity: { id: relation.id, symbolCode },
      })
      // } else {
      //   result.relations.push(relation)
      // }
    }
  }

  return result
}

export class EntityTransformer {
  templates: EntityTemplate[]

  constructor(templates: EntityTemplate[], meta) {
    this.templates = templates
    this.meta = meta
  }

  getEntityTemplate(name: EntityName) {
    const template = this.templates.find(t => t.symbolCode === name)
    assert(template, `Template for "${name}" not found`)

    return template
  }

  reverseTransformEntity(obj: TransformedEntity): RawEntity {
    const result: any = {
      id: obj.id,
      parentId: obj.parentId,
      symbolCode: obj.symbolCode,
      createdAt: obj.createdAt,
      updatedAt: obj.updatedAt,
      properties: [],
      relations: [],
      chats: obj.chats,
      users: [],
    }

    if (obj.properties) {
      for (let [key, value] of Object.entries(obj.properties)) {
        if (!value) continue
        result.properties.push({
          symbolCode: key,
          ...value,
        })
      }
    }

    if (obj.relations) {
      for (let [key, value] of Object.entries(obj.relations)) {
        if (_.isNull(value)) {
          value = []
        }
        if (!value) {
          continue
        }
        //для одиночных связей
        if (_.isNumber(value)) {
          value = [value]
        }
        if (_.isArray(value)) {
          result.relations.push({
            symbolCode: key,
            entities: value.map(rv => {
              if (_.isNumber(rv)) {
                return { id: rv }
              } else return this.reverseTransformEntity(rv)
            }),
          })
        }
      }
    }

    if (obj.userIdsByTypeId) {
      const res: { id: number; typeId: number }[] = []
      if (
        Object.entries(obj.userIdsByTypeId).some(([key, v]) => _.isNumber(v)) ||
        Object.entries(obj.userIdsByTypeId).some(([key, v]) => _.isArray(v))
      ) {
        Object.entries(obj.userIdsByTypeId).map(([key, value]) => {
          if(value) {
            if (_.isNumber(value)) {
              value = [value]
            }
            for (let userId of value) {
              res.push({
                id: userId,
                typeId: Number(key),
              })
            }
          }
        })
      }
      result.users = res
    }

    return result
  }

  transformEntity(obj: RawEntity): TransformedEntity {
    const template = this.getEntityTemplate(obj.symbolCode)
    assert(template)

    const result = {
      id: obj.id,
      parentId: obj.parentId,
      symbolCode: obj.symbolCode,
      $origin: obj,
      createdAt: obj.createdAt,
      updatedAt: obj.updatedAt,
      properties: {},
      relations: {},
      userIdsByTypeId: {},
      chats: obj.chats,
      chatIdsByTypeId: {},
    }

    if (obj.properties) {
      result.properties = transformListToDict(
        obj.properties.map(prop => {
          const propTemplate = template.properties.find(
            p => p.symbolCode === prop.symbolCode
          )
          assert(propTemplate)

          const res = { ...prop }

          const valueVariants = propTemplate.type.valueVariants
          if (valueVariants) {
            res.$selectValue = valueVariants.find(
              vv => vv.id === Number(prop.value)
            )?.value
          }

          return res
        })
      )
    }

    if (_.isArray(obj.relations)) {
      result.relations = {}

      // проход по relations (1 уровень, сгруппирваны по symbolCode)
      for (const relatedEntity of obj.relations) {
        if (_.isArray(relatedEntity.entities)) {
          const list = []

          if (this.meta?.original) {
            if (
              relatedEntity.symbolCode === 'nutritionProgramDay' ||
              relatedEntity.symbolCode === 'trainingProgram'
            ) {
              for (const entity of relatedEntity.entities) {
                list.push(entity.id)
              }
            } else {
              for (const entity of relatedEntity.entities) {
                list.push(this.transformEntity(entity))
              }
            }
          } else {
            // проход по entities (2 уровень)
            for (const entity of relatedEntity.entities) {
              list.push(entity.id)
            }
          }

          if(list.every(el => el?.properties?.position)){
            list.sort(compareNumeric)
          }

          result.relations[relatedEntity.symbolCode] = list
        }
      }
    }

    if (Array.isArray(obj.users)) {
      for (const user of obj.users) {
        const list = result.userIdsByTypeId[user.typeId] ?? []
        list.push(user.id)

        result.userIdsByTypeId[user.typeId] = list
      }
    }

    if (Array.isArray(obj.chats)) {
      for (const chat of obj.chats) {
        const list = result.chatIdsByTypeId[chat.typeId] ?? []
        list.push(chat.id)

        result.chatIdsByTypeId[chat.typeId] = list
      }
    }

    return result
  }

  transformEntities(data: RawEntity[]) {
    return data.map(i => this.transformEntity(i))
  }
}

export const transformDictToList = (obj: Dictionary): List => {
  if (!obj) return []
  return Object.entries(obj).map(([key, value]) => {
    return { symbolCode: key, ...value }
  })
}

export const transformRelationsToList = (obj: Dictionary) => {
  if (!obj) return {}
  console.log(obj)
  const arr = Object.entries(obj).map(([key, value]) => {
    return {
      symbolCode: value?.entities['symbolCode'],
      value: value.entities['id'],
    }
  })

  return arr.reduce((obj, item) => {
    if (!obj[item.symbolCode]) {
      obj[item.symbolCode] = []
    }
    obj[item.symbolCode].push(item.value)
    return obj
  }, {})
}

export const transformUsersToList = (obj: Dictionary) => {
  if (!obj) return {}
  let result = null
  Object.entries(obj).forEach(([key, value]) => {
    result = value?.id
  })
  return result
}

export const transformListToDict = (list: List): Dictionary => {
  const result: any = {}
  if(list) {
    for (let i in list) {
      result[list[i].symbolCode] = _.omit(list[i], 'symbolCode')
    }
  }
  return result
}
