import {
  ArrayInput,
  Datagrid,
  Filter,
  Labeled,
  ListBase,
  ListToolbar,
  Loading,
  Pagination,
  ResourceContextProvider,
  SimpleFormIterator,
  SimpleFormIteratorItemContext,
  TextField,
  TextInput,
  useDataProvider,
  useInput,
  useListContext,
  useResourceContext,
  useUnselectAll
} from "react-admin"
import React, { useEffect, useState } from "react"
import { EntityTemplate } from "~/@types/base-app"
import { EntityName } from "~/constants"
import { parseResource } from "~/modules/parse-resource"

import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import { useFormContext } from "react-hook-form"
import { produce } from "immer"
import { AnimationField } from "~/components/inputs/AnimationField"
import { VideoField } from "~/components/inputs/VideoField"

export const CreateExerciseDialog = () => {
  const resource = useResourceContext()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [template, setTemplate] = useState<EntityTemplate>()
  const [templateProgramExercise, setTemplateProgramExercise] = useState<EntityTemplate>()
  const [entityName, setEntityName] = useState<EntityName>()

  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    const loadTemplate = async () => {
      const {
        entityName: name,
        template,
        templates
      } = await parseResource("1:exercise")

      setTemplate(template)
      setTemplateProgramExercise(
        templates.find(
          prop => prop.symbolCode === "trainingProgramDayExercise"
        )
      )
      setEntityName(name)
    }

    loadTemplate()
  }, [resource])

  const unselectAll = useUnselectAll("1:exercise")

  const handlerDialogClose = () => {
    setOpen(false)
    unselectAll()
  }

  useEffect(() => {
    const handleUnload = () => {
      handlerDialogClose()
    }

    window.addEventListener("unload", handleUnload)
    return () => {
      window.removeEventListener("unload", handleUnload)
    }
  }, [])

  return (
    <>
      <Button variant="outlined" onClick={() => setOpen(!open)}>
        Добавить упражнение
      </Button>
      <ChooseComponent
        template={template}
        templateProgramExercise={templateProgramExercise}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
      <Dialog
        sx={{ minHeight: 700 }}
        maxWidth={"xl"}
        open={open}
        onClose={handlerDialogClose}
      >
        <ResourceContextProvider value={`1:exercise`}>
          <ListBase disableSyncWithLocation>
            <DialogComponent setOpen={setOpen} template={template} />
          </ListBase>
        </ResourceContextProvider>
      </Dialog>
    </>
  )
}

const DialogComponent = ({ setOpen, template }) => {
  const { selectedIds } = useListContext()

  const dataProvider = useDataProvider()
  const { setValue } = useFormContext()
  const unselectAll = useUnselectAll("1:exercise")

  const {
    field: { value }
  } = useInput({ source: `relations.trainingProgramDayExercise` })

  const closeFunc = () => {
    const getProduct = async () => {
      const { data } = await dataProvider.getMany(`1:exercise`, {
        ids: selectedIds
      })

      const newValue = produce(data, draft => {
        for (const exercise of draft) {
          exercise.symbolCode = "trainingProgramDayExercise"
          exercise.parentId = exercise.id
          delete exercise.id
        }
      })

      setValue(
        "relations.trainingProgramDayExercise",
        [...value, ...newValue],
        { shouldDirty: true }
      )
    }
    getProduct()

    setOpen(false)
    unselectAll()
  }

  return (
    <>
      <div style={{ margin: "0 0 35px 0" }}>
        <ListToolbar filters={<CustomFilter />} />
      </div>
      <Datagrid rowClick={null}>
        <TextField label="ID" source="id" />
        <TextField label="Название" source="properties.name.value" />
        <TextField label="Описание" source="properties.description.value" />
        <TextField
          label="Техника выполнения"
          source="properties.technique.value"
        />
      </Datagrid>
      <div style={{ margin: " 35px 0 0 0" }}>
        <Pagination />
      </div>
      <Button variant="outlined" onClick={closeFunc}>
        Выбрать
      </Button>
    </>
  )
}

const ChooseComponent = ({ template, isLoading, setIsLoading, templateProgramExercise }) => {
  const {
    field: { value: productIds }
  } = useInput({ source: `relations.trainingProgramDayExercise` })

  if (productIds.length === 0) return <></>

  return isLoading ? (
    <Loading />
  ) : (
    <Labeled label={"Упражнения"}>
      <ArrayInput label={""} source={`relations.trainingProgramDayExercise`}>
        <SimpleFormIterator inline addButton={<></>} disableClear={true}>
          <SimpleFormIteratorItemContext.Consumer>
            {({ index }) => <ExerciseItem index={index} templateProgramExercise={templateProgramExercise} />}
          </SimpleFormIteratorItemContext.Consumer>
        </SimpleFormIterator>
      </ArrayInput>
    </Labeled>
  )
}

const ExerciseItem = ({ index, templateProgramExercise }) => {
  return (
    <>
      {!templateProgramExercise ? (
        <Loading />
      ) : (
        templateProgramExercise.properties.map(el =>
          el.symbolCode === "name" ||
            el.symbolCode === "description" ||
            el.symbolCode === "technique" ? (
              <TextInput
                readOnly
                label={`${el.name}`}
                source={`relations.trainingProgramDayExercise[${index}].properties.${el.symbolCode}.value`}
              />
            )
            : el.symbolCode === "video" ? (
              <VideoField label={el?.name} />
            ) : el.symbolCode === "animation" ? (
              <AnimationField label={el?.name} />
            ) : el.symbolCode === "position" ? (
              <TextInput
                label={`${el.name}`}
                source={`relations.trainingProgramDayExercise[${index}].properties.${el.symbolCode}.value`}
                defaultValue={index+1}
              />
            ) : (
              <TextInput
                label={`${el.name}`}
                source={`relations.trainingProgramDayExercise[${index}].properties.${el.symbolCode}.value`}
              />
            )
        )
      )}
    </>
  )
}

const CustomFilter = props => {
  return (
    <Filter {...props}>
      <TextInput
        alwaysOn
        source="properties.name.value"
        label={"Введите название упражнения"}
      />
    </Filter>
  )
}
