import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  DateInput,
  EmailField,
  FunctionField,
  ImageField,
  Labeled,
  SelectArrayInput,
  SelectInput,
  TextField,
  TextInput,
  TimeInput
} from "react-admin"
import moment from "moment"
import React from "react"
import { LatLngInput } from "~/components/inputs/LatLngInput"
import { ScheduleInput } from "~/components/inputs/ScheduleInput"
import { VideoField } from "~/components/inputs/VideoField"
import { AnimationField } from "~/components/inputs/AnimationField"
import { FileInputCustom } from "~/components/inputs/FileInputCustom"
import { ImageInput } from "~/components/inputs/ImageInput"
import StarBorderIcon from "@mui/icons-material/StarBorder"
import StarHalfIcon from "@mui/icons-material/StarHalf"
import StarIcon from "@mui/icons-material/Star"
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt"
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt"
import { NullInput } from "~/components/inputs/NullInput"

export const arrayEntity = [
  { name: "gym" },
  { name: "trainerGym" },
  { name: "training" },
  { name: "stream" },
  { name: "trainingProgram" },
  { name: "trainingProgramDay" },
  { name: "exercise" },
  { name: "exerciseGroup" },
  { name: "trainingProgramDayExercise" },
  { name: "set" },
  { name: "nutritionProgramDayMealDish" }
]
export const isImageField = ({ type: { settings } }: any) => {
  return settings.type === "file"
}
export const isPriceField = ({ symbolCode }: any) => {
  return symbolCode === "price"
}
export const isStartedAt = ({ symbolCode }: any) => {
  return symbolCode === "startedAt"
}
export const isAddressField = ({ symbolCode }: any) => {
  return symbolCode === "address"
}
export const isDateField = ({ symbolCode }: any) => {
  return symbolCode === "date"
}
export const isTimeField = ({ symbolCode }: any) => {
  return symbolCode === "time"
}
export const isLongField = ({ symbolCode }: any) => {
  return (
    symbolCode === "agoraToken" ||
    symbolCode === "description" ||
    symbolCode === "technique" ||
    symbolCode === "recipe"
  )
}
export const isReviewsInfo = ({ symbolCode }: any) => {
  return symbolCode === "reviewsInfo"
}
export const isLikesInfo = ({ symbolCode }: any) => {
  return symbolCode === "likesInfo"
}
export const isFunctionFieldFromDate = ({ type: { settings } }: any) => {
  return settings.type === "schedule"
}
export const isSelectMultiple = ({ type: { settings } }: any) => {
  return settings.type === "select" && settings.multiple
}
export const isSelectField = ({ type: { settings } }: any) => {
  return settings.type === "select"
}
export const isBooleanField = ({ type: { settings } }: any) => {
  return settings.type === "boolean"
}
export const isEmailField = ({ type: { settings } }: any) => {
  return settings.type === "email"
}
export const isDefaultField = ({ type: { settings } }: any) => {
  return settings === null
}
export const getFieldForApp = (el: any, list = "") => {
  if (el.symbolCode === "longitude" || el.symbolCode === "latitude" || el.symbolCode === "sort") return null

  if (isDefaultField(el)) {
    return (
      <TextField
        key={`${el.id}-${el.name}-${el.symbolCode}`}
        label={el.name}
        source={`properties.${el.symbolCode}.value`}
      />
    )
  }

  if (isSelectMultiple(el)) {
    return (
      <FunctionField
        key={`${el.id}-${el.name}-${el.symbolCode}`}
        label={el.name}
        render={(record: any) => {
          return (
            <div key={`${el.id}-${el.name}-${el.symbolCode}`}>
              {
                record?.properties?.[el.symbolCode]?.value &&
                record?.properties?.[el.symbolCode]?.value.map((a: any) => (
                  <div key={`${el.symbolCode}-${a}`}>{el?.type?.valueVariants?.find((variant: any) => variant.id === a)?.value || null}</div>
                ))
              }
            </div>
          )
        }}
      />
    )
  }

  if (isSelectField(el)) {
    return (
      <TextField
        key={`${el.id}-${el.name}-${el.symbolCode}`}
        label={el.name}
        source={`properties.${el.symbolCode}.$selectValue`}
      />
    )
  }

  if (isBooleanField(el)) {
    return (
      <BooleanField
        readOnly={true}
        key={`${el.id}-${el.name}-${el.symbolCode}`}
        label={el.name}
        source={`properties.${el.symbolCode}.value`}
      />
    )
  }

  if (isReviewsInfo(el)) {
    return (
      <FunctionField
        key={`${el.id}-${el.name}-${el.symbolCode}`}
        label={el.name}
        render={(record: any) => {
          const averageStar = record.properties[`${el.symbolCode}`]?.value?.average ?? 0
          return (
            <div style={{ display: "flex" }}>
              <span style={{ fontSize: "20px", fontWeight: 500 }}>{averageStar}</span>
              {
                averageStar < 3 ? <StarBorderIcon />
                  : averageStar > 3 ? <StarIcon />
                    : <StarHalfIcon />
              }
            </div>
          )
        }}
      />
    )
  }

  if (isLikesInfo(el)) {
    return (
      <FunctionField
        key={`${el.id}-${el.name}-${el.symbolCode}`}
        label={el.name}
        render={(record: any) => {
          const likes = record.properties?.likesInfo?.value?.detailedCount?.find(({ reaction, quantity }: {
            reaction: string,
            quantity: number
          }) => reaction === "like")?.quantity ?? 0
          const disLikes = record.properties?.likesInfo?.value?.detailedCount?.find(({ reaction, quantity }: {
            reaction: string,
            quantity: number
          }) => reaction === "dislike")?.quantity ?? 0
          return (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ fontSize: "18px", fontWeight: 500 }}>
                  {likes}
                </span>
                <ThumbUpAltIcon />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ fontSize: "18px", fontWeight: 500 }}>
                  {disLikes}
                </span>
                <ThumbDownAltIcon />
              </div>
            </div>
          )
        }}
      />
    )
  }

  if (isEmailField(el)) {
    return (
      <EmailField
        key={`${el.id}-${el.name}-${el.symbolCode}`}
        label={el.name}
        source={`properties.${el.symbolCode}.value`}
      />
    )
  }
  if (isImageField(el)) {
    if (el.symbolCode === "animation") {
      return (
        <AnimationField
          key={`${el.id}-${el.name}-${el.symbolCode}`}
          label={el?.name}
        />
      )
    }
    if (el.symbolCode === "video") {
      return (
        <VideoField
          key={`${el.id}-${el.name}-${el.symbolCode}`}
          label={el?.name}
        />
      )
    }
    return (
      <ImageField
        key={`${el.id}-${el.name}-${el.symbolCode}`}
        label={el.name}
        source={`properties.${el.symbolCode}.value`}
        src="url"
      />
    )
  }
  if (isLongField(el)) {
    return (
      <FunctionField
        key={`${el.id}-${el.name}-${el.symbolCode}`}
        label={el.name}
        render={(record: any) => {
          if (
            !record.properties[`${el.symbolCode}`] ||
            !record.properties[`${el.symbolCode}`]?.value
          ) {
            return null
          }
          if (
            record.properties[`${el.symbolCode}`]?.value.length > 20 &&
            list !== "show"
          ) {
            return (
              record.properties[`${el.symbolCode}`].value.slice(0, 20) + "..."
            )
          }
          return <div>{record.properties[`${el.symbolCode}`].value}</div>
        }}
      />
    )
  }
  if (isFunctionFieldFromDate(el)) {
    return (
      <FunctionField
        label="Режим работы"
        key={`${el.id}-${el.name}-${el.symbolCode}`}
        render={(record: any) => {
          if (
            !record.properties ||
            !record.properties.schedule ||
            !record.properties.schedule.value
          )
            return null
          const arrayDate = Object.values(record.properties.schedule.value)
          return (
            <div>
              {(arrayDate || []).map(([[timeFrom, timeEnd]], index) => {
                return (
                  <div key={index} style={{ minWidth: "160px" }}>
                    {moment()
                      .day(index + 1)
                      .format("dddd")}{" "}
                    {`${timeFrom} - ${timeEnd}`}
                  </div>
                )
              })}
            </div>
          )
        }}
      />
    )
  }

  return (
    <TextField
      // emptyText={'Не заполнено'}
      key={`${el.id}-${el.name}-${el.symbolCode}`}
      label={el.name}
      source={`properties.${el.symbolCode}.value`}
    />
  )
}

export const getFieldForEditAndCreate = (el: any, entityName = "") => {
  if (el.symbolCode === "longitude" || el.symbolCode === "latitude" || el.symbolCode === "sort") return null
  if (isDefaultField(el)) {
    return (
      <Labeled key={`${el.id}-${el.name}-${el.symbolCode}`} label={el.name}>
        <TextInput source={`properties.${el.symbolCode}.value`} />
      </Labeled>
    )
  }
  if (isPriceField(el)) {
    return (
      <Labeled key={`${el.id}-${el.name}-${el.symbolCode}`} label={el.name}>
        <TextInput source={`properties.${el.symbolCode}.value`} readOnly />
      </Labeled>
    )
  }
  if (isStartedAt(el)) {
    return (
      <Labeled key={`${el.id}-${el.name}-${el.symbolCode}`} label={el.name}>
        <TextInput source={`properties.${el.symbolCode}.value`} readOnly />
      </Labeled>
    )
  }
  if (isAddressField(el)) {
    return <LatLngInput key={`${el.id}-${el.name}-${el.symbolCode}`} el={el} />
  }
  if (isDateField(el)) {
    return (
      <Labeled key={`${el.id}-${el.name}-${el.symbolCode}`} label={el.name}>
        <DateInput source={`properties.${el.symbolCode}.value`} />
      </Labeled>
    )
  }
  if (isTimeField(el)) {
    const formatTime = (inputTime: string) => {
      return inputTime ? inputTime.split(":").slice(0, 2).join(":") : "" // Преобразование времени в формат "HH:mm"
    }

    const parseTime = (inputTime: string) => {
      return inputTime.concat(":00")
    }

    return (
      <Labeled key={`${el.id}-${el.name}-${el.symbolCode}`} label={el.name}>
        <TimeInput
          source={`properties.${el.symbolCode}.value`}
          format={formatTime}
          parse={parseTime}
        />
      </Labeled>
    )
  }
  if (isSelectMultiple(el)) {

    return (
      <Labeled key={`${el.id}-${el.name}-${el.symbolCode}`} label={el.name}>
        <AutocompleteArrayInput
          source={`properties.${el.symbolCode}.value`}
          choices={el.type.valueVariants}
          optionText="value"
          optionValue={'id'}
          filterToQuery={searchText => ({name: searchText})}
        />
      </Labeled>
    )
  }
  if (isSelectField(el)) {
    return (
      <Labeled key={`${el.id}-${el.name}-${el.symbolCode}`} label={el.name}>
        <SelectInput
          source={`properties.${el.symbolCode}.value`}
          choices={el.type.valueVariants}
          optionText="value"
          optionValue="id"
        />
      </Labeled>
    )
  }
  if (isBooleanField(el)) {
    return (
      <BooleanInput
        key={`${el.id}-${el.name}-${el.symbolCode}`}
        label={el.name}
        source={`properties.${el.symbolCode}.value`}
      />
    )
  }
  if (isImageField(el)) {
    if (el.symbolCode === "animation" || el.symbolCode === "video") {
      return (
        <FileInputCustom
          key={`${el.id}-${el.name}-${el.symbolCode}`}
          label={el.name}
          source={`properties.${el.symbolCode}.value`}
          multiple={el.type.settings.multiple}
          accept={el.type.settings.mime}
        />
      )
    }

    return (
      <ImageInput
        key={`${el.id}-${el.name}-${el.symbolCode}`}
        el={el}
      />
    )
  }
  if (isFunctionFieldFromDate(el)) {
    return (
      <ScheduleInput
        key={`${el.id}-${el.name}-${el.symbolCode}`}
        property={el}
      />
    )
  }
  if (isLongField(el)) {
    return (
      <Labeled key={`${el.id}-${el.name}-${el.symbolCode}`} label={el.name}>
        <TextInput source={`properties.${el.symbolCode}.value`} multiline={true}
          style={{ overflow: "auto", maxHeight: "200px", width: "320px" }} />
      </Labeled>
    )
  }

  if(entityName && entityName === "projectComponent" && el.symbolCode === "url") {
    return (
      <Labeled key={`${el.id}-${el.name}-${el.symbolCode}`} label={el.name}>
        <NullInput el={el} />
      </Labeled>
    )
  }

  return (
    <Labeled key={`${el.id}-${el.name}-${el.symbolCode}`} label={el.name}>
      <TextInput source={`properties.${el.symbolCode}.value`} />
    </Labeled>
  )
}
