import { Labeled, Loading, useDataProvider, useRecordContext, useRedirect } from "react-admin"
import Button from "@mui/material/Button"
import ChatIcon from "@mui/icons-material/Chat"
import React, { useState } from "react"
import { CHATS_TYPES } from "~/constants"


export const OpenChatButton = () => {
  const record = useRecordContext()
  const dataProvider = useDataProvider()
  const redirect = useRedirect()
  
  const [chatId, setChatId] = useState<number | null>(null)

  if(!record) return <Loading />

  const chatFilter = {
    typeId: CHATS_TYPES.TECHNICAL_SUPPORT,
    users: [
      {id: record.id}
    ]
  }

  const chatFunc = () => {
    dataProvider.getList(
      'chats',
      {
        filter: chatFilter,
        pagination: { page: 1, perPage: 5},
        sort: { field: "id", order: "ASC"}
      }
    ).then(res => {
      if(res.total !== 0) {
        setChatId(res.data[0].id)
      }
    })
    if(!chatId) {
      return "Создать чат"
    }
    return "Открыть чат"
  }

  const openFunc = () => {
    if(chatId) {
      redirect("edit", `chats/${chatId}`)
    } else {
      dataProvider.create(
        'chats',
        { data: chatFilter}
      ).then(res => {
        redirect("edit", `chats/${res.data.id}`)
      })
    }
  }

  return (
    <Labeled label={"Чат с пользователем"} >
      <Button
        variant="outlined"
        sx={{ height: 48, marginTop: 1}}
        endIcon={<ChatIcon />}
        onClick={openFunc}
      >
        {chatFunc()}
      </Button>
    </Labeled>
  )
}