import { DataProvider, withLifecycleCallbacks } from 'react-admin'
import {
  parserParamsForEntityGetList,
  parserParamsForGetManyReference,
} from '~/utils/dataProviderParams'
import {
  Response,
  ResponseArrayNumbers,
  ResponseArrayObjects,
  ResponseObject,
} from '~/utils/types'
import EntityApi from '~/api/entity-api'
import { EntityTransformer } from '../../modules/entity'
import {
  removeObjectsWithNullOrUndefinedValues,
  transformRelationInTrainingProgramDayInTraining,
  transformRelationSymbolCodeId,
} from '~/utils/transformRelation'
import { arrayEntity } from '~/utils/getField'

import { parseResource } from '~/modules/parse-resource'

export const baseEntityDataProvider: DataProvider = {
  async getList(resource: string, params): Promise<Response> {
    // --
    // в начале каждого метода дата провайдера
    const { applicationId, entityName, templates } = await parseResource(
      resource
    )

    // TODO: Скорректировать CRUD методы EntityApi, чтобы они принимали applicationId
    // как EntityApi.getList(applicationId, entityName, filter)
    // --

    const filter = parserParamsForEntityGetList(params)
    const template = templates.find(t => t.symbolCode === entityName)
    const pull = {
      relations: template.relations
        .filter(el => el.template.symbolCode !== 'exercise')
        .map(r => {
          return {
            symbolCode: r.template.symbolCode,
            entities: { properties: true },
          }
        }),
    }
    const req = { ...filter, pull }
    const res = await EntityApi.getList(applicationId, entityName, req)
    // TODO: старый transformEntity выводим из использования
    // TODO: getEntityTemplate тоже
    const transfomer = new EntityTransformer(templates)
    const data = transfomer.transformEntities(res.data.rows)
    console.log('data-provider.getList', data)
    return { data, total: res.data.count }
  },

  async getMany(
    resource: string,
    { ids }: { ids: ResponseArrayNumbers }
  ): Promise<ResponseArrayObjects> {
    const { applicationId, entityName, templates } = await parseResource(
      resource
    )

    const template = templates.find(t => t.symbolCode === entityName)
    const pull = {
      relations: template.relations
        .filter(el => el.template.symbolCode !== 'exercise')
        .map(r => ({
          symbolCode: r.template.symbolCode,
          entities: { properties: true },
        })),
    }

    const res = await EntityApi.getList(applicationId, entityName, {
      filter: { id: ids },
      pull,
    })
    const transfomer = new EntityTransformer(templates)
    const data = transfomer.transformEntities(res.data.rows)
    console.log('data-provider.getMany', data)
    return { data: data }
  },

  async getManyReference(resource: string, params): Promise<Response> {

    const { applicationId, entityName, templates } = await parseResource(
      resource
    )
    const filter = parserParamsForGetManyReference(params, resource)
    const template = templates.find(t => t.symbolCode === entityName)
    const pull = {
      relations: template.relations
        .filter(el => el.template.symbolCode !== 'exercise')
        .map(r => ({
          symbolCode: r.template.symbolCode,
          entities: { properties: true },
        })),
    }
    const req = { ...filter, pull }
    const res = await EntityApi.getList(applicationId, entityName, req)
    const transfomer = new EntityTransformer(templates)
    const data = transfomer.transformEntities(res.data.rows)
    console.log('data-provider.getManyReference', data)
    return { data: data, total: res.data.count }
  },

  async getOne(resource, { id, meta = false }): Promise<ResponseObject> {
    const { applicationId, entityName, templates } = await parseResource(
      resource
    )
    const template = templates.find(t => t.symbolCode === entityName)

    const pull = meta.fullTree
      ? { relations: buildDependensyTreeRecursive(templates, entityName) }
      : {
        relations: template.relations.map(r => ({
          symbolCode: r.template.symbolCode,
          entities: { properties: true },
        })),
      }

    // if (meta.fullTree && meta.original && meta.getTransformed) pull.relations.push({
    //   symbolCode: "nutritionProgramDay",
    //   entities: { properties: true }
    // })

    if (meta.fullTree && meta.original && meta.getTransformed) {
      template.relations.find(entity => {
        if (entity.typeId === 3) {
          pull.relations.push({
            symbolCode: entity.template.symbolCode,
            entities: { properties: true },
          })
        }
      })
    }

    const res = await EntityApi.getOne(applicationId, entityName, id, pull)
    const transfomer = new EntityTransformer(templates, meta)
    const data = transfomer.transformEntity(res.data)

    if (meta.fullTree && !meta.getTransformed) {
      const { relations, ...obj } = data
      return {
        data: {
          relations: data.$origin.relations,
          ...obj,
        },
      }
    }
    console.log('data-provider.getOne', data)
    return {
      data,
    }
  },

  async create(resource: string, params): Promise<ResponseObject> {
    const { applicationId, entityName, templates } = await parseResource(
      resource
    )
    const paramsWithoutNullValues = removeObjectsWithNullOrUndefinedValues(
      params.data
    )
    const transformer = new EntityTransformer(templates)
    const reverseTransform = transformer.reverseTransformEntity(
      paramsWithoutNullValues
    )
    const res = await EntityApi.create(applicationId, entityName, {
      ...reverseTransform,
    })
    const data = transformer.transformEntity(res.data)
    console.log('data-provider.create', data)
    return { data }
  },

  async update(resource: string, params): Promise<ResponseObject> {
    const { applicationId, entityName, templates } = await parseResource(
      resource
    )
    const paramsWithoutNullValues = removeObjectsWithNullOrUndefinedValues(
      params.data
    )
    const transformer = new EntityTransformer(templates)
    const reverseTransform = transformer.reverseTransformEntity(
      paramsWithoutNullValues
    )
    const res = await EntityApi.update(
      applicationId,
      entityName,
      params.data.id,
      // regData
      { ...reverseTransform }
    )
    const transfomer = new EntityTransformer(templates)
    const data = transfomer.transformEntity(res.data)
    console.log('data-provider.update', data)
    return { data }
  },

  async updateMany(resource: string, params): Promise<ResponseObject> {
    console.log('🚧 UpdateMany:', resource, '\n', params)
    return { data: undefined }
  },

  async delete(
    resource: string,
    { id }: { id: number }
  ): Promise<ResponseObject> {
    const { applicationId, entityName, templates } = await parseResource(
      resource
    )
    const res = await EntityApi.delete(applicationId, entityName, id)
    console.log('data-provider.delete', res.data)
    return { data: res.data }
  },

  async deleteMany(resource: string, params): Promise<ResponseObject> {
    console.log('🚧 deleteMany:', resource, '\n', params)
    return { data: undefined }
  },
}

export const entityDataProvider: DataProvider = withLifecycleCallbacks(
  baseEntityDataProvider,
  [
    // {
    //   resource: '1:training',
    //   beforeSave: async (params, dataProvider: DataProvider) => {
    //     const paramsWithoutNullValues =
    //       removeObjectsWithNullOrUndefinedValues(params)
    //
    //     console.log(params)
    //
    //     const relations = []
    //     if (!params?.trainerGym && !params.trainingProgramDayInTraining) {
    //       return {
    //         ...paramsWithoutNullValues,
    //         relations,
    //       }
    //     }
    //     if (params.trainerGym) {
    //       relations.push({
    //         entity: {
    //           symbolCode: 'trainerGym',
    //           id: params.trainerGym,
    //         },
    //       })
    //     }
    //     if (params.trainingProgramDayInTraining) {
    //       relations.push(
    //         ...transformRelationInTrainingProgramDayInTraining([
    //           ...params.trainingProgramDayInTraining,
    //         ])
    //       )
    //     }
    //
    //     return {
    //       ...paramsWithoutNullValues,
    //       relations,
    //     }
    //   },
    // },
    {
      resource: '1:trainingProgramDayExercise',
      beforeCreate: async (params, dataProvider: DataProvider) => {
        const paramsWithoutNullValues = removeObjectsWithNullOrUndefinedValues(
          params.data
        )
        // work with users
        const userId = params.data.user
        console.log(params.data)
        const users = userId
          ? [
            {
              id: userId,
              typeId: 1,
            },
          ]
          : []
        if (params.data.relations.exerciseGroup === undefined)
          return {
            data: {
              ...paramsWithoutNullValues,
              users,
            },
          }

        const relation = transformRelationSymbolCodeId(params.data.relations)

        return {
          data: {
            ...paramsWithoutNullValues,
            relations: relation,
            users,
          },
        }
      },
      // @ts-ignore
      beforeUpdate: async (params, dataProvider: DataProvider) => {
        const paramsWithoutNullValues = removeObjectsWithNullOrUndefinedValues(
          params.data
        )

        const objectKeys = Object.keys(params.data)

        const filteredKeys = objectKeys.filter(key => {
          return arrayEntity.find(entity => entity.name === key)
        })

        const filteredObject = filteredKeys.reduce((result, key) => {
          result[key] = params.data[key]
          return result
        }, {})

        const relation = transformRelationSymbolCodeId(filteredObject)

        // work with users
        const userId = params.data.user
        console.log({ userId })
        const users = userId
          ? [
            {
              id: userId,
              typeId: 1,
            },
          ]
          : []

        return {
          data: {
            ...paramsWithoutNullValues,
            relations: relation,
            users,
          },
        }
      },
    },
  ]
)

const buildDependensyTreeRecursive = (
  templates,
  currentEntityName,
  entityName
) => {
  const currentTemplate = templates.find(
    e => e.symbolCode === currentEntityName
  )
  return currentTemplate.relations
    .filter(r => {
      const template = templates.find(
        e => e.symbolCode === r.template.symbolCode
      )
      const relation = template.relations.find(
        e => e.template.symbolCode === currentEntityName
      )
      return (
        r.template.symbolCode !== 'review' && relation && relation.typeId === 3
      )
    })
    .map(r => ({
      symbolCode: r.template.symbolCode,
      entities: {
        properties: true,
        relations: buildDependensyTreeRecursive(
          templates,
          r.template.symbolCode,
          currentEntityName
        ),
      },
    }))
}
