import {
  ArrayInput,
  Datagrid,
  Filter,
  ImageField,
  Labeled,
  ListBase,
  ListToolbar,
  Loading,
  Pagination,
  ResourceContextProvider,
  SimpleFormIterator,
  SimpleFormIteratorItemContext,
  TextField,
  TextInput,
  useDataProvider,
  useInput,
  useListContext,
  useResourceContext,
  useUnselectAll
} from "react-admin"
import React, { useEffect, useState } from "react"
import { EntityTemplate } from "~/@types/base-app"
import { parseResource } from "~/modules/parse-resource"

import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import { useFormContext } from "react-hook-form"
import { produce } from "immer"
import { VideoField } from "~/components/inputs/VideoField"

const nameButton = {
  projectComponentFormattedText: 'Текст',
  projectComponentQuote: 'Отзыв',
  projectComponentSlider: 'Слайдер',
  projectComponentSliderSlide: 'Состовные части слайдера',
  projectComponentVideo: 'Видео',
  projectComponentDevelopers: 'Разработчиков'
}

export const CreateProjectDialog = ({ entityName } ) => {
  const resource = useResourceContext()
  const [applicationId, resourceName] = resource.split(':')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [template, setTemplate] = useState<EntityTemplate>()
  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    const loadTemplate = async () => {
      const {
        templates
      } = await parseResource(`${applicationId}:${resourceName}`)

      setTemplate(templates.find(
        prop => prop.symbolCode === entityName
      ))

    }

    loadTemplate()
  }, [resource])

  const unselectAllVideo = useUnselectAll(`${applicationId}:${entityName}`)

  const handlerDialogClose = () => {
    setOpen(false)
    unselectAllVideo()
  }

  useEffect(() => {
    const handleUnload = () => {
      handlerDialogClose()
    }

    window.addEventListener("unload", handleUnload)
    return () => {
      window.removeEventListener("unload", handleUnload)
    }
  }, [])

  return (
    <>
      <Button variant="outlined" onClick={() => setOpen(!open)}>
        Добавить {nameButton[entityName]}
      </Button>
      <ChooseComponent
        template={template}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        applicationId={applicationId}
        entityName={entityName}
      />
      <Dialog
        sx={{ minHeight: 700 }}
        maxWidth={"xl"}
        open={open}
        onClose={handlerDialogClose}
      >
        <ResourceContextProvider value={`${applicationId}:${entityName}`}>
          <ListBase disableSyncWithLocation>
            <DialogComponent setOpen={setOpen} template={template} applicationId={applicationId} entityName={entityName}/>
          </ListBase>
        </ResourceContextProvider>
      </Dialog>
    </>
  )
}

const DialogComponent = ({ setOpen, template, applicationId, entityName}) => {
  const { selectedIds } = useListContext()
  const dataProvider = useDataProvider()
  const { setValue } = useFormContext()
  const unselectAll = useUnselectAll(`${applicationId}:${entityName}`)

  const {
    field: { value }
  } = useInput({ source: `relations.${entityName}` })

  const closeFunc = () => {
    const getProduct = async () => {
      const { data } = await dataProvider.getMany(`${applicationId}:${entityName}`, {
        ids: selectedIds
      })

      const newValue = produce(data, draft => {
        for (const item of draft) {
          // delete item.id
          item.symbolCode = entityName
        }
      })

      setValue(
        `relations.${entityName}`,
        [ ...newValue],
        { shouldDirty: true }
      )
    }
    getProduct()

    setOpen(false)
    unselectAll()
  }

  const newItem = () => {
    const newEmptyItem = template.properties.reduce((acc, el) => {
      if (el.symbolCode !== 'sort') {
        acc.properties[el.symbolCode] = { value: '' }
      }
      return acc
    }, { properties: {} })

    setValue(`relations.${entityName}`, [...value, newEmptyItem], { shouldDirty: true })
    setOpen(false)
    unselectAll()
  }

  const renderFields = () => {
    return template?.properties.map((el) => {
      if (el.type.settings.type === "string") {
        return (
          <TextField
            key={el.id}
            label={el.name}
            source={`properties.${el.symbolCode}.value`}
          />
        )
      }
      if (el.type.settings.type === "number" && el.symbolCode === "sort") {
        console.log({el})
        return null
      }
      if (el.type.settings.type === "file" && (el.symbolCode === "backgroundImage" || el.symbolCode === "image") ) {
        return <ImageField
          key={`${el.id}-${el.name}-${el.symbolCode}`}
          label={el.name}
          source={`properties.${el.symbolCode}.value`}
          src="url"
        />
      }
      if (el.type.settings.type === "url") {
        return (
          <TextField
            key={el.id}
            label={el.name}
            source={`properties.${el.symbolCode}.value`}
          />
        )
      }
      return null
    })
  }

  return (
    <div style={{margin:'100px 10px 50px 10px'}}>
      {/*<div style={{ margin: "0 0 35px 0" }}>*/}
      {/*  <ListToolbar filters={<CustomFilter />} />*/}
      {/*</div>*/}
      <Datagrid rowClick={null}  >
        <TextField label="ID" source="id" />
        {renderFields()}
      </Datagrid>

      <div style={{ margin: " 35px 0 0 0" }}>
        <Pagination />
      </div>

      <div style={{width:'100%', display: "flex", justifyContent:'space-around',}}>
        <Button variant="outlined" onClick={closeFunc}>
          Выбрать
        </Button>
        {/*<Button variant="outlined" onClick={newItem} >*/}
        {/*  Создать*/}
        {/*</Button>*/}
      </div>
    </div>
  )
}

const ChooseComponent = ({ template, isLoading, applicationId, entityName}) => {
  const {
    field: { value: productIds }
  } = useInput({ source: `relations.${entityName}` })

  if (productIds.length === 0) return <></>

  return isLoading ? (
    <Loading />
  ) : (
    <Labeled label={nameButton[entityName]}>
      <ArrayInput label={""} source={`relations.${entityName}`}>
        <SimpleFormIterator inline addButton={<></>} disableClear={true}>
          <SimpleFormIteratorItemContext.Consumer>
            {({ index }) =>  <ExerciseItem index={index} template={template} entityName={entityName}/>}
          </SimpleFormIteratorItemContext.Consumer>
        </SimpleFormIterator>
      </ArrayInput>
    </Labeled>
  )
}

const ExerciseItem = ({ index, template, entityName }) => {
  return (
    <>
      {!template ? (
        <Loading />
      ) : (
        template.properties.map(el => {
          if (el.type.settings.type === "string") {
            return <TextInput
              readOnly
              key={`${el.id}-${el.name}-${el.symbolCode}`}
              label={`${el.name}`}
              source={`relations.${entityName}[${index}].properties.${el.symbolCode}.value`}
            />
          }
          if (el.type.settings.type === "file" && (el.symbolCode === "backgroundImage" || el.symbolCode === "image")) {
            return <>
              <ImageField
                key={`${el.id}-${el.name}-${el.symbolCode}`}
                label={el.name}
                source={`relations.${entityName}[${index}].properties.${el.symbolCode}.value`}
                src="url"
              />
              {/*для кейса кгда мы хотим сами вставлять картинки в созданный элемент, надо только править ImageInput*/}
              {/*<ImageInput el={el} index={index} entityName={entityName}/>*/}
            </>
          }
          if (el.type.settings.type === "file" && el.symbolCode === "url") {
            return <VideoField
              key={`${el.id}-${el.name}-${el.symbolCode}`}
              label={el?.name} />
          }

          return null

        }
        )
      )}
    </>
  )
}

const CustomFilter = props => {
  return (
    <Filter {...props}>
      <TextInput
        alwaysOn
        source="properties.name.value"
        label={"Введите название упражнения"}
      />
    </Filter>
  )
}
