export enum UserEntityRelationType {
  Owner = 1,
  Member = 2,
}

export enum EntityRelationType {
  Has = 1,
  HasMany = 2,
  Belongs = 3,
  BelongsToMany = 4,
}

export enum ChatType {
  One = 1, // "Личный чат"
  Group = 2, // "Групповой чат"
  Moder = 3, // "Модерационный диспут"
  Stream = 4, // "Стрим-чат"
  Inner = 5, // "Вводный чат"
}

export enum EntityName {
  Gym = 'gym',
  TrainerGym = 'trainerGym',

  Stream = 'stream',

  Exercise = 'exercise',
  ExerciseGroup = 'exerciseGroup',

  TrainingProgram = 'trainingProgram',
  TrainingProgramDay = 'trainingProgramDay',
  TrainingProgramDayExercise = 'trainingProgramDayExercise',

  Dish = 'dish',

  NutritionProgram = 'nutritionProgram',
  NutritionProgramDay = 'nutritionProgramDay',
  NutritionProgramDayMeal = 'nutritionProgramDayMeal',
  NutritionProgramDayMealDish = 'nutritionProgramDayMealDish',
  NutritionProgramDayMealDishProduct = 'nutritionProgramDayMealDishProduct',

  ClientTrainingProgram = 'clientTrainingProgram',
  ClientTrainingProgramDay = 'clientTrainingProgramDay',
  ClientTrainingProgramDayExercise = 'clientTrainingProgramDayExercise',

  /** тренировка */
  Training = 'training',
  /** связанна с: тренировкой, имеет: конкретные упражнения */
  TrainingProgramDayInTraining = 'trainingProgramDayInTraining',
  /** содержит: плановые параметры для упражнения, имеет: сеты */
  TrainingProgramDayInTrainingExercise = 'trainingProgramDayInTrainingExercise',
  /** содержит: фактические параметры упраженения */
  Set = 'set',

  Measurement = 'measurement',

  Review = 'review',

  Project = 'project',
  ProjectComponent = 'projectComponent',
  ProjectComponentFormattedText = 'projectComponentFormattedText',
  ProjectComponentQuote = 'projectComponentQuote',
  ProjectComponentSlider = 'projectComponentSlider',
  ProjectComponentSliderSlide = 'projectComponentSliderSlide',
  ProjectComponentVideo = 'projectComponentVideo',
}

export enum CHATS_TYPES {
  DIRECT = 1,
  GROUP = 2,
  MODERATION_DISPUTE = 3,
  STREAM_CHAT = 4,
  INITIAL_CHAT = 5,
  TECHNICAL_SUPPORT = 6
}