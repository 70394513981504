import { useEffect } from 'react'
import { Socket, io } from 'socket.io-client'
import configApi from '~/constants/config'
import _ from 'lodash'
import Cookies from 'universal-cookie'
import { useCookies } from "react-cookie"

const cookies = new Cookies()

export const chatSocket = io(`${process.env.REACT_APP_CHAT_SOCKET_URL}`, {
  autoConnect: false,
})

export const SocketService = () => {
  const [reactCookies, setReactCookie, removeReactCookie] = useCookies(['token'])

  useEffect(() => {
    if(!reactCookies?.token) {
      return
    }
    const connect = async () => {
      chatSocket.io.opts.extraHeaders = {
        'auth-access-token': cookies.get('token'),
        'auth-type': cookies.get('auth-type'),
      }

      chatSocket.on('connect', () => {
        console.log('> chat socket | connected')
      })
      chatSocket.on('connect_error', error => {
        console.log('> chat socket | connect_error: ' + error.message)
      })
      chatSocket.on('error', error => {
        console.log('> chat socket | error: ' + error.message)
      })
      chatSocket.connect()
    }

    connect()

    return () => {
      chatSocket.removeAllListeners()
      chatSocket.disconnect()
    }
  }, [reactCookies])

  return null
}

export const checkError = (response, resolve, reject) => {
  const arg1 = _.isArray(response) ? response[0] : response

  if (arg1?.message) {
    reject(arg1)
  } else {
    resolve(response)
  }
}

export const errorGuard = (handler, resolve, reject) => {
  return response => {
    const arg1 = _.isArray(response) ? response[0] : response

    if (arg1?.message) {
      reject(arg1)
    } else {
      resolve(response)
    }
  }
}

export const asyncEmit = (dSocket: Socket, ...args) => {
  return new Promise((resolve, reject) => {
    dSocket.emit(...args, response => {
      if (response?.message) {
        console.error(...args, response)
        reject(response)
      } else {
        console.log(...args, response)
        resolve(response)
      }
    })
  })
}
