import React, { useEffect } from 'react'
import { Box, Button, Stack, useTheme } from '@mui/material'
import { useDropzone } from 'react-dropzone'
import {
  FileInputClasses,
  ImageField,
  shallowEqual,
  useInput,
} from 'react-admin'
import { convertFileToBase64 } from '~/utils/image'
import { VideoField } from '~/components/inputs/VideoField'
import { AnimationField } from '~/components/inputs/AnimationField'

export const FileInputCustom = ({
  label,
  source,
  disabled,
  readOnly,
  multiple,
  inputProps,
  accept,
}: {
  label?: string
  source?: string
  disabled?: boolean
  readOnly?: boolean
  multiple?: boolean
  inputProps?: any
  accept?: string | string[]
}) => {
  const theme = useTheme()

  const transformFile = file => {
    if (!(file instanceof File)) {
      return file
    }

    const preview = URL.createObjectURL(file)
    const transformedFile = {
      rawFile: file,
      src: preview,
      title: file.name,
      name: file.name,
      mime: file.type,
      localUrl: preview,
    }

    return transformedFile
  }

  const transformFiles = (files: any[]) => {
    if (!files) {
      return multiple ? [] : null
    }

    if (Array.isArray(files)) {
      return files.map(transformFile)
    }

    return transformFile(files)
  }

  const {
    id,
    field: { onChange, onBlur, value },
    fieldState,
    formState: { isSubmitted },
    isRequired,
  } = useInput({
    format: transformFiles,
    parse: transformFiles,
    source,
    disabled,
    readOnly,
  })

  const files = value ? (Array.isArray(value) ? value : [value]) : []

  const onDrop = (newFiles, rejectedFiles, event) => {
    const updatedFiles = multiple ? [...files, ...newFiles] : [...newFiles]

    onChange(updatedFiles)
    onBlur()
  }

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    multiple,
    onDrop,
    disabled: disabled || readOnly,
    noClick: true,
    accept,
  })

  const handleRemove = async index => {
    const file = value[index]

    if (multiple) {
      const filteredFiles = files.filter(
        stateFile => !shallowEqual(stateFile, file)
      )
      onChange(filteredFiles as any)
      onBlur()
    } else {
      onChange(null)
      onBlur()
    }
  }

  useEffect(() => {
    // файлы, которые уже имеют value или которые были изначально
    const hasFilesWithoutValue = files.filter(f => !f.url).some(f => !f.value)

    if (!hasFilesWithoutValue) {
      return
    }

    ;(async () => {
      const updatedFiles = await Promise.all(
        files.map(async file => {
          if (file.url || file.value) {
            return file
          }

          const base64 = await convertFileToBase64(file)

          return {
            ...file,
            value: base64,
          }
        })
      )

      onChange(updatedFiles)
    })()
  }, [files])

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        minHeight: 100,
      }}
      paddingBottom={3}
    >
      <Box
        {...getRootProps({
          className: FileInputClasses.dropZone,
          'data-testid': 'dropzone',
          style: {
            color:
              disabled || readOnly
                ? theme.palette.text.disabled
                : inputProps?.color || theme.palette.text.primary,
            backgroundColor:
              disabled || readOnly
                ? theme.palette.action.disabledBackground
                : inputProps?.backgroundColor,
          },
        })}
      >
        <Box
          style={{
            fontSize: '0.75em',
            marginBottom: '0.2em',
            marginTop: 8,
            color: 'rgba(0, 0, 0, 0.6)',
          }}
        >
          {label}
        </Box>

        <input id={id} name={id} {...getInputProps()} />
        <Stack flexDirection="row" gap={3} paddingTop={1} paddingBottom={1}>
          {files.map((el, index) => {
            if (
              el.mime === 'video/mp4' ||
              el.mime === 'video/x-msvideo' ||
              el.mime === 'video/quicktime' ||
              el.mime === 'video/webm'
            ) {
              return (
                <Stack>
                  <Stack
                    position={'relative'}
                    key={index}
                    style={styles.itemWrapper}
                  >
                    <VideoField el={el} />

                    <Button
                      sx={styles.buttonStyle}
                      variant={'outlined'}
                      onClick={() => handleRemove(index)}
                    >
                      —
                    </Button>
                  </Stack>
                  <Box>Название: {el.name}</Box>
                </Stack>
              )
            }

            if (el.mime === 'application/json') {
              return (
                <Stack>
                  <Stack
                    position={'relative'}
                    key={index}
                    style={styles.itemWrapper}
                  >
                    <AnimationField el={el} />
                    <Button
                      sx={styles.buttonStyle}
                      variant={'outlined'}
                      onClick={() => handleRemove(index)}
                    >
                      —
                    </Button>
                  </Stack>
                  <Box>Название: {el.name}</Box>
                </Stack>
              )
            }

            if (el.rawFile) {
              return (
                <>
                  <Stack position={'relative'} key={index}>
                    <Button
                      sx={styles.buttonStyle}
                      variant={'outlined'}
                      onClick={() => handleRemove(index)}
                    >
                      —
                    </Button>
                    {el.name}
                  </Stack>
                </>
              )
            }

            return (
              <Stack position={'relative'} key={index}>
                <Button
                  sx={styles.buttonStyle}
                  variant={'outlined'}
                  onClick={() => handleRemove(index)}
                >
                  —
                </Button>
                <Box> {el.name}</Box>
              </Stack>
            )
          })}
        </Stack>

        <Button
          sx={{ width: '100%', maxWidth: 120, marginLeft: 'auto' }}
          variant={'outlined'}
          onClick={open}
        >
          Загрузить
        </Button>
      </Box>
    </Box>
  )
}

const styles = {
  itemWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 5,
  },
  buttonStyle: {
    minWidth: '20px',
    height: '20px',
    borderRadius: '50%',
    padding: 0,
    borderColor: 'red',
    backgroundColor: 'red',
    color: 'white',
    '&:hover': {
      backgroundColor: 'darkred',
      borderColor: 'darkred',
    },
  },
}
