import { transformDictToListFromFilter } from './transformRelation'

export const searchFilter = (params): { newFilter: object; query: string } => {
  const { query, ...newFilter } = params
  const hasQuery = query && query !== ''
  if (hasQuery) {
    delete newFilter.query
  }
  return {
    newFilter,
    query: hasQuery ? query : '',
  }
}

export const searchFilterEntity = (
  params
): { filter: object; query: string } => {
  const { query, ...filter } = params
  const newFilter = {
    ...filter,
    ...(query && query !== '' ? { query } : {}),
  }

  if (newFilter.properties) {
    return {
      ...newFilter,
      properties: transformDictToListFromFilter(newFilter.properties),
    }
  }

  return newFilter
}

export const getFilter = (
  resource: string,
  filter
  // applicationId: number
): object => {



  if (resource !== 'applications') {
    return {
      ...filter,
      // ...(applicationId !== 0 && { applicationId }),
    }
  } else {
    return filter
  }
}
