import { TextInput } from "react-admin"
import React from "react"
import { useFormContext } from "react-hook-form"

export const NullInput = ({ el }) => {
  const { setValue, watch } = useFormContext()
  const source = `properties.${el.symbolCode}.value`

  const value = watch(source)
  const handler = (e: any) => {
    if(!e?.target?.value) {
      setValue(source,  '',{shouldDirty: true})
    }
  }

  return (
    <TextInput
      value={value}
      source={source}
      onChange={handler}
    />
  )
}