import {
  List,
  DatagridConfigurable,
  TextField,
  BulkDeleteWithConfirmButton,
  TextInput,
  Edit,
  SimpleForm,
  Create,
  EditButton,
  Labeled,
  DeleteButton,
  FunctionField,
  SelectArrayInput,
  ReferenceInput,
  PasswordInput,
  DateInput,
  FilterForm,
  FilterButton,
  SelectColumnsButton,
  ReferenceField,
  SelectInput,
  ChipField,
  SingleFieldList,
  useNotify,
  ReferenceArrayField,
  Pagination,
  ArrayField,
  WithListContext,
  SimpleFormIterator,
  ArrayInput,
  useGetList, DateField, TimeInput
} from "react-admin"
import moment from 'moment'
import { Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useAppContext } from '../modules/app-context'
import { changeField, changeFieldValues } from '~/utils/errorsHandler'
import { PropertyInput, DynamicInput } from '../ui/property-input'
import SettingsApi from '~/api/settings-api'
import { ApplicationId } from "~/@types"
import { OpenChatButton } from "~/components/buttons/OpenChatButton"

export const Users = () => {
  const { applicationId } = useAppContext()

  return (
    <List pagination={<PostPagination />} filter={{ applicationId }}>
      <ListToolbar />
      <DatagridConfigurable bulkActionButtons={<UserButtons />} rowClick="edit">
        <TextField label="Id" source="id" />
        <TextField label="Почта" source="email" />
        <FunctionField
          label="Роли"
          source="roles"
          render={record => record.roles.map(role => role.name).join(', ')}
        />
        {/* <ReferenceField
          label="Приложения"
          source="applicationId"
          reference="applications"
        >
          <TextField source="name" />
        </ReferenceField> */}
        <FunctionField
          label="Дата создания"
          source="createdAt"
          render={e => `${moment(e.createdAt).format('YYYY-MM-DD')}`}
        />
        <FunctionField
          label="Дата обновления"
          source="updatedAt"
          render={e => `${moment(e.createdAt).format('YYYY-MM-DD')}`}
        />
        <EditButton />
        <DeleteButton />
      </DatagridConfigurable>
    </List>
  )
}

const ListToolbar = () => (
  <Stack
    direction="row"
    justifyContent="flex-start"
    alignItems="center"
    spacing={0}
    sx={{ ml: 2, mr: 2 }}
  >
    <FilterForm filters={postFilters} />
    <div>
      <SelectColumnsButton />
      <FilterButton filters={postFilters} />
    </div>
  </Stack>
)
const PostPagination = () => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
)

const regex = /^[\w-]+@([\w-]+\.)+[\w-]{2,4}$/g
const CheckNameValue = (value, allValues: { email: string }, props) => {
  return allValues.email && allValues.email.match(regex)
    ? undefined
    : 'Введите почту (test@test.aa)'
}

const CheckRoleValue = (value, allValues: { roleId: string[] }, props) =>
  allValues.roleId && allValues.roleId.length ? undefined : 'Выберите роль'

const CheckApplicationValue = (
  value,
  allValues: { applicationId: ApplicationId },
  props
) => (allValues.applicationId ? undefined : 'Выберите приложение')

// Форма редактирования сущности
export const UsersEdit = () => {
  const { applicationId } = useAppContext()
  return (
    <Edit title="Изменение пользователя">
      <SimpleForm>
        <div style={{ display: 'flex', gap: '16px' }}>
          <Labeled label="Id пользователя">
            <TextInput source="id" readOnly />
          </Labeled>
          <Labeled label="Уникальный токен">
            <TextInput source="uniqueToken" readOnly />
          </Labeled>
          <OpenChatButton />
        </div>
        <Labeled label="Подписка">
          <>
            <div style={{ display: 'flex', gap: '16px' }}>
              <DateInput label={'Начало'}  source={"subscription.startedAt"} readOnly/>
              <TimeInput label={'Время'} source={"subscription.startedAt"} readOnly/>
            </div>
            <div style={{ display: 'flex', gap: '16px' }}>
              <DateInput  label={'Окончание'} source={"subscription.endsAt"} readOnly/>
              <TimeInput label={'Время'} source={"subscription.endsAt"} readOnly/>
            </div>
          </>
        </Labeled>
        <Labeled label="Почта">
          <TextInput label="" source="email" validate={CheckNameValue} />
        </Labeled>
        {applicationId ? (
          <Labeled label="Роли">
            <ReferenceArrayField source="roleId" reference="roles">
              <SingleFieldList>
                <ChipField source="name" size="small" />
              </SingleFieldList>
            </ReferenceArrayField>
          </Labeled>
        ) : (
          <Labeled label="Список ролей">
            <ReferenceInput source="roleId" reference="roles">
              <SelectArrayInput
                label=""
                source="roles"
                optionValue="id"
                optionText="name"
                validate={CheckRoleValue}
              />
            </ReferenceInput>
          </Labeled>
        )}
        {applicationId ? (
          <Labeled label="Приложение">
            <ReferenceField source="applicationId" reference="applications">
              <ChipField source="name" size="small" />
            </ReferenceField>
          </Labeled>
        ) : (
          <Labeled label="Список приложений*">
            <ReferenceInput source="applicationId" reference="applications">
              <SelectInput
                label=""
                source="applications"
                optionValue="id"
                optionText="name"
                validate={CheckApplicationValue}
              />
            </ReferenceInput>
          </Labeled>
        )}
        <ArrayField source="properties">
          <WithListContext
            render={({ data }) => {
              return (
                <div
                  // style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: '16px' }}
                  style={{ display: 'inline-block' }}
                >
                  {data.map((item, index) => {
                    if (item.symbolCode === 'reviewsInfo') {
                      return null
                    } else
                      return (
                        <div key={index} style={{ maxWidth: '200' }}>
                          <PropertyInput
                            label={item?.property?.name}
                            item={item}
                            source={`properties[${index}].${
                              !item.value &&
                              item.property.type.settings.type === 'file'
                                ? 'file'
                                : 'value'
                            }`}
                          />
                        </div>
                      )
                  })}
                </div>
              )
            }}
          />
        </ArrayField>
      </SimpleForm>
    </Edit>
  )
}

// Форма создания сущности
export const UserCreate = () => {
  const { applicationId } = useAppContext()
  const { data: properties } = useGetList('users-properties')
  const [selectedRoles, setSelectedRoles] = useState([])
  const [region, setRegion] = useState([])
  const [currency, setCurrency] = useState([])

  useEffect(() => {
    SettingsApi.getRegions().then(res => setRegion(res.data))
    SettingsApi.getCurrencies().then(res => setCurrency(res.data))
  }, [])

  const setRoles = value => {
    if (value?.target?.value) {
      setSelectedRoles([value.target.value])
    }
    return value?.target?.value || []
  }

  const filter = value => {
    for (const { id } of value.roles) {
      if (selectedRoles.includes(id)) return true
    }
    return false
  }
  const notify = useNotify()
  const onError = error => {
    let obj = JSON.parse(error.request.responseText).message.reduce(
      (acc, { name, value }) => ({
        ...acc,
        [name]: value,
      })
    )
    notify(
      `${changeField(obj.field)}: ${changeFieldValues(obj.field, obj.value)}`,
      {
        type: 'error',
      }
    )
  }
  return (
    <Create mutationOptions={{ onError }} title="Создание пользователя">
      <SimpleForm
        defaultValues={{
          applicationId: applicationId,
        }}
      >
        <Labeled label="Почта пользователя">
          <TextInput label="" source="email" />
        </Labeled>
        <Labeled label="Пароль">
          <PasswordInput label="" source="password" />
        </Labeled>
        <Labeled label="Роли">
          <ReferenceInput
            source="roles"
            reference="roles"
            filter={{ applicationId }}
          >
            <SelectInput
              label=""
              source="name"
              optionText="name"
              onChange={value => setRoles(value)}
            />
          </ReferenceInput>
        </Labeled>
        <Labeled label="Регион">
          <SelectInput
            choices={region}
            source="regionId"
            optionValue="id"
            optionText="name"
            required
          />
        </Labeled>
        <Labeled label="Валюта">
          <SelectInput
            choices={currency}
            source="currencyId"
            optionValue="id"
            optionText="name"
            required
          />
        </Labeled>
        {selectedRoles.length > 0 && (
          <Labeled label="Свойства">
            <ArrayInput label="" source="properties">
              <SimpleFormIterator inline>
                <ReferenceInput
                  source="symbolCode"
                  reference="users-properties"
                  perPage={100}
                  sort={{ field: 'name', order: 'ASC' }}
                  queryOptions={{
                    meta: {
                      filter,
                      selectedRoles,
                    },
                  }}
                >
                  <SelectInput
                    label=""
                    source="symbolCode"
                    optionValue="symbolCode"
                    optionText="name"
                  />
                </ReferenceInput>
                <DynamicInput label="" source="value" properties={properties} />
              </SimpleFormIterator>
            </ArrayInput>
          </Labeled>
        )}
      </SimpleForm>
    </Create>
  )
}

const UserButtons = () => (
  <>
    <BulkDeleteWithConfirmButton mutationMode="pessimistic" />
  </>
)

// Список полей фильтрации
const postFilters = [
  <TextInput label="Поиск" source="query" alwaysOn />,
  <TextInput label="Id" source="id" defaultValue="" />,
  <TextInput label="Почта" source="email" defaultValue="" />,
  // <TextInput label="Id приложения" source="applicationId" defaultValue="" />,
  <DateInput label="Дата создания" source="createdAt" />,
  <DateInput label="Дата изменения" source="updatedAt" />,
]
