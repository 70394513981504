export const transformRelationSymbolCodeId = (obj: Dictionary) => {
  const transformedData = []

  for (const [key, value] of Object.entries(obj)) {
    if (Array.isArray(value)) {
      transformedData.push(
        ...value.map(id => ({
          symbolCode: key,
          entities: [
            {
              id
            }
          ]
        }))
      )
    } else {
      transformedData.push({
        symbolCode: key,
        entities: [
          {
            id: value
          }
        ]
      })
    }
  }
  return transformedData
}

export const transformRelationInTrainingProgramDayInTraining = arr => {
  if (!arr) return []
  const result = []

  const processItem = item => {
    if (typeof item === "number") {
      result.push({
        entity: {
          symbolCode: "trainingProgramDayInTraining",
          id: item,
          exist: false
        }
      })
    } else if (typeof item === "object" && item !== null) {
      const key = Object.keys(item)[0]
      const value = item[key]

      if (
        Array.isArray(value) &&
        value.length > 0 &&
        typeof value[0] === "object"
      ) {
        // Если значение является массивом объектов, рекурсивно обрабатываем каждый объект
        value.forEach(obj => processItem(obj))
      } else {
        result.push({ entity: { symbolCode: key, id: value, exist: false } })
      }
    }
  }

  arr.forEach(processItem)
  return result
}

export const transformDictToListFromFilter = (obj: Dictionary) => {
  if (!obj) return []
  return Object.entries(obj).map(([key, value]) => {
    // return { symbolCode: key, value: { substring: value } }
    return { symbolCode: key, value: { substring: value.value } }
  })
}

export const removeObjectsWithNullOrUndefinedValues = obj => {
  if (obj && obj.properties && obj.properties instanceof Object) {
    // Фильтруем массив properties, оставляя только те объекты, где value не null и не undefined
    obj.properties = Object.fromEntries(
      Object.entries(obj.properties).filter(
        ([key, prop]) =>
          // @ts-ignore
          prop.value !== null && prop.value !== undefined
      )
    )

    // Рекурсивно вызываем функцию для вложенных объектов
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] instanceof Object) {
        obj[key] = removeObjectsWithNullOrUndefinedValues(obj[key])
      }
    }
  } else if(obj && obj.relations) {
    for(const key in obj) {
      if (obj[key] instanceof Object) {
        obj[key] = removeObjectsWithNullOrUndefinedValues(obj[key])
      }
    }
  }

  return obj
}
