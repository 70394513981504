import { addDebugInterceptors } from '~/utils/api'
import axios from 'axios'
import Cookies from 'universal-cookie'

let cookies = new Cookies()
export const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
})

instance.interceptors.request.use(config => {
  config.headers['auth-access-token'] = cookies.get('token')
  config.headers['auth-type'] = cookies.get('auth-type')

  return config
})
addDebugInterceptors(instance)
